<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <div class="pa-5 mb-5">
            <v-card-title
              primary-title
              class="title"
            >
              Validation des saisies d'adhésions
            </v-card-title>
            <div>
              <div v-if="isUserNational">
                <v-alert
                  color="primary"
                  dense
                  type="info"
                  :value="!workflowCdpeId"
                >
                  Veuillez sélectionner un
                  <strong>Département</strong>
                </v-alert>
                <v-autocomplete
                  v-model="currentCdpe"
                  :items="filterDepartements"
                  label="Département"
                  :item-text="item => `${item.code} - ${item.nom}`"
                  item-value="id"
                  @change="changeDepartement"
                />
              </div>
              <div>
                <v-autocomplete
                  v-if="isUserDepartemental || currentCdpe !== null"
                  :items="getFilterConseilsLocaux"
                  :item-text="item => `${item.code} - ${item.nom}`"
                  label="Conseil Local"
                  return-object
                  clearable
                  @change="changeConseilLocal"
                />
              </div>
            </div>

            <div class="mb-5">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  Montant local :
                </v-col>
                <v-col
                  cols="2"
                  class="text-right"
                >
                  {{ totalLocal | currency }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  Montant départemental :
                </v-col>
                <v-col
                  cols="2"
                  class="text-right"
                >
                  {{ totalDepartemental | currency }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  Montant national :
                </v-col>
                <v-col
                  cols="2"
                  class="text-right"
                >
                  {{ totalNational | currency }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  Montant total à envoyer :
                </v-col>
                <v-col
                  cols="2"
                  class="text-right"
                >
                  {{ amount | currency }}
                </v-col>
              </v-row>
            </div>
            <v-btn
              color="primary"
              :disabled="canNotBeSend"
              @click="confirmSendReglements"
            >
              Envoyer au CDPE
            </v-btn>
          </div>
        </v-card>

        <div>
          <v-data-table
            v-model="selected"
            light
            :headers="headers"
            :items="validationSaisiesAdhesionLocalWithUniqKey"
            item-key="key"
            show-select
            class="elevation-3"
            :loading="loading"
            loading-text="Chargement en cours"
            no-data-text="Aucun parent à valider"
            sort-by="conseil_local.nom"
            :items-per-page="50"
            :footer-props="{
              itemsPerPageText: 'Parents par page:',
              itemsPerPageOptions: [20, 50, 100, 200],
            }"
            @item-selected="onItemSelected"
          >
            <v-progress-linear
              v-if="loading"
              v-slot:progress
              color="blue"
            />

            <template v-slot:no-data>
              Aucun parent à valider
            </template>

            <template v-slot:header.data-table-select>
              <v-checkbox
                :input-value="selectAllValue"
                :indeterminate="selectAllIndeterminate"
                @click.stop="toggleAll"
              />
            </template>

            <template v-slot:item.total_par_destination.local="{ item }">
              {{ item.total_par_destination.local | currency }}
            </template>

            <template v-slot:item.total_par_destination.departemental="{ item }">
              {{ item.total_par_destination.departemental | currency }}
            </template>

            <template v-slot:item.total_par_destination.national="{ item }">
              {{ item.total_par_destination.national | currency }}
            </template>
          </v-data-table>

          <v-progress-linear
            v-if="loading"
            :indeterminate="true"
          />
        </div>
      </v-col>
    </v-row>
    <Downloader
      :downloading-file="downloadingFile"
      label="bordereau de validation"
      @downloadSuccess="onDownloadSuccess"
    />
    <ConfirmedModal
      v-if="dialog"
      :sentence="`Êtes-vous sûr de vouloir valider ces entrées et envoyer un règlement de ${this.$options.filters.currency(amount)} à votre CDPE ?`"
      btn-action="Envoyer"
      @close="dialog = false"
      @confirmed="sendReglements()"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Downloader from '../partials/Downloader';
import ConfirmedModal from '../reusableComponents/confirmedModal';
import typeRecu from '../../config/typeRecuValidation';
import selectAllMixin from '../../mixins/vTableDataSelectAll';

export default {
  name: 'ValidationSaisiesAdhesion',
  components: {
    Downloader,
    ConfirmedModal,
  },
  mixins: [selectAllMixin],
  data: () => ({
    currentCdpe: null,
    headers: [
      {
        text: 'Parent',
        sortable: true,
        value: 'nomPrenom',
      },
      {
        text: 'Conseil Local Principal',
        sortable: true,
        value: 'conseil_local_principal.nom',
      },
      {
        text: 'Conseil Local Secondaire',
        sortable: true,
        value: 'conseil_local.nom',
      },
      {
        text: 'Montant local',
        sortable: false,
        value: 'total_par_destination.local',
      },
      {
        text: 'Montant départemental',
        sortable: false,
        value: 'total_par_destination.departemental',
      },
      {
        text: 'Montant national',
        sortable: false,
        value: 'total_par_destination.national',
      },
    ],
    dialog: false,
    downloadingFile: null,
  }),
  computed: {
    ...mapGetters({
      validationSaisiesAdhesionLocal: 'getValidationSaisiesAdhesionLocal',
      loading: 'getWorkflowLoading',
      workflowCdpeId: 'getValidationSaisiesAdhesionLocalCdpeId',
      workflowCdpeOrClId: 'getValidationSaisiesAdhesionLocalCdpeOrClId',
    }),

    ...mapGetters('session', {
      isUserNational: 'isUserNational',
      isUserDepartemental: 'isUserDepartemental',
      getUserCdpeId: 'getUserCdpeId',
    }),
    ...mapGetters('cdpe', {
      filterDepartements: 'getFilterDepartements',
    }),
    ...mapGetters('conseilLocal', [
      'getFilterConseilsLocaux',
    ]),
    validationSaisiesAdhesionLocalWithUniqKey() {
      return this.validationSaisiesAdhesionLocal.map(parent => {
        parent.key = `${parent.parent_id}-${parent.conseil_local.id}`;
        parent.nomPrenom = `${parent.nom} ${parent.prenom}`;
        return parent;
      });
    },
    items() {
      return this.validationSaisiesAdhesionLocalWithUniqKey;
    },
    totaux() {
      return (this.selected || []).reduce(
        (totaux, item) => ({
          local: totaux.local + item.total_par_destination.local,
          departemental:
            totaux.departemental + item.total_par_destination.departemental,
          national: totaux.national + item.total_par_destination.national,
        }),
        { local: 0, departemental: 0, national: 0 },
      );
    },
    totalLocal() {
      return this.totaux.local;
    },
    totalDepartemental() {
      return this.totaux.departemental;
    },
    totalNational() {
      return this.totaux.national;
    },
    canNotBeSend() {
      return (this.selected || []).length === 0;
    },
    amount() {
      return this.totalNational + this.totalDepartemental;
    },
  },
  created() {
    if (this.isUserDepartemental) {
      this.currentCdpe = this.getUserCdpeId;
    }
  },
  methods: {
    ...mapActions([
      'loadValidationSaisiesAdhesionLocal',
      'validateSaisiesAdhesionLocal',
    ]),
    ...mapActions('conseilLocal', {
      loadFilterConseilsLocaux: 'loadFilterConseilsLocaux',
    }),
    changeDepartement(cdpeId) {
      this.selected.length = [];
      this.loadFilterConseilsLocaux(cdpeId);
      this.loadValidationSaisiesAdhesionLocal({
        cdpeOrConseilLocalId: cdpeId,
        cdpeId: cdpeId,
      });
    },
    changeConseilLocal(conseilLocal) {
      this.selected = [];
      if (!conseilLocal) {
        this.loadValidationSaisiesAdhesionLocal({
          cdpeOrConseilLocalId: this.currentCdpe,
          cdpeId: this.currentCdpe,
        });
      } else {
        this.loadValidationSaisiesAdhesionLocal({
          cdpeOrConseilLocalId: conseilLocal.id,
          cdpeId: this.currentCdpe,
        });
      }

    },
    onItemSelected(selectedItem) {
      /** Sélectionne et désélectionne tous les items lié au même parent */
      if (selectedItem.value) {
        for (const item of this.validationSaisiesAdhesionLocalWithUniqKey) {
          if (item.parent_id == selectedItem.item.parent_id) {
            if (!this.selected.find(alreadySelectedItem => item.key == alreadySelectedItem.key)) {
              this.selected.push(item);
            }
          }
        }
      } else {
        for (const item of this.validationSaisiesAdhesionLocalWithUniqKey) {
          if (item.parent_id == selectedItem.item.parent_id) {
             this.selected = this.selected.filter(alreadySelectedItem => item.parent_id != alreadySelectedItem.parent_id);
          }
        }
      }
    },
    confirmSendReglements() {
      this.dialog = true;
    },
    sendReglements() {
      this.dialog = false;
      this.validateSaisiesAdhesionLocal({
        cdpeId: this.workflowCdpeId,
        parentIds: this.selected.map(parent => ({ id: parent.parent_id })),
      }).then(response => {
        let paramsUrl = {
          typeValidation: typeRecu.VALIDATION_SAISIES_ADHESION_LOCALES,
          listLotId: response.lots.map(e => e.id),
        };
        this.downloadingFile = {
          url: '/api/recuvalidation',
          params: paramsUrl,
        };
      });
    },
    onDownloadSuccess() {
      this.loadValidationSaisiesAdhesionLocal({
        cdpeOrConseilLocalId: this.workflowCdpeOrClId,
        cdpeId: this.workflowCdpeId,
      });
    },
  },
};
</script>
