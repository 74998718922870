<template>
  <div>
    <ValidationSaisiesAdhesion />
  </div>
</template>

<script>
import ValidationSaisiesAdhesion from '../../components/workflow/ValidationSaisiesAdhesion';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ValidationSaisiesAdhesionContainer',
  components: {
    ValidationSaisiesAdhesion,
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters('session', {
      getPerimetreUser: 'getPerimetreUser',
      isUserDepartemental: 'isUserDepartemental',
      isUserNational: 'isUserNational',
      getUserCdpeId: 'getUserCdpeId',
    }),
  },

  created() {
    if (this.isUserNational) {
      this.loadFilterDepartements();
    }else {
      if (this.isUserDepartemental){
        this.loadFilterConseilsLocaux(this.getUserCdpeId);
      }
      this.loadValidationSaisiesAdhesionLocal({
        cdpeOrConseilLocalId: this.getPerimetreUser.perimetre_id,
        cdpeId: this.getUserCdpeId,
      });
    }
  },
  methods: {
    ...mapActions([
      'loadValidationSaisiesAdhesionLocal',
    ]),
    ...mapActions('cdpe', [
      'loadFilterDepartements',
    ]),
    ...mapActions('conseilLocal', [
      'loadFilterConseilsLocaux',
    ]),
  },
};
</script>
